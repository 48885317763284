import _ from 'lodash';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import shortid from 'shortid';
import {
  orderRequirements,
  ERRORS,
  genomeOptions,
  formatOptions,
  defaultYieldOptions,
  defaultNucleaseOptions
} from './constants';
import { filterPrices } from 'features/order/pricing/utilities';
import { hfCas12MaxBlockedCountries } from 'features/order/sgrna/constants';

//Select wells that are filled on a plate
export const selectFilledPlateWells = plate =>
  _.filter(
    plate.wells,
    well => !_.isEmpty(well?.name) && !_.isEmpty(well?.sequence)
  );

//Select all wells on a plate that have any errors
export const selectErrorPlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.length);

//Select all wells on a plate that have a SEQUENCE_INVALID error
export const selectInvalidSequencePlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.includes(ERRORS.SEQUENCE_INVALID));

//Select all wells on a plate that have a NAME_INVALID error
export const selectInvalidNamePlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.includes(ERRORS.NAME_INVALID));

//Select all wells on a plate that have a NAME_NON_UNIQUE error
export const selectNonUniqueNamePlateWells = plate =>
  _.filter(plate.wells, well => well.errors?.includes(ERRORS.NAME_NON_UNIQUE));

//Select all filled wells on the project and flatten to a single array
export const selectFlatFilledProjectWells = project =>
  _.flatten(_.map(project.plates, plate => selectFilledPlateWells(plate)));

//Select all filled wells on the project keeping them grouped by plate
export const selectFilledProjectWells = project =>
  _.map(project.plates, plate => selectFilledPlateWells(plate));

//Select all invalid wells on the project keeping them grouped by plate
export const selectErrorProjectWells = project =>
  _.map(project.plates, plate => selectErrorPlateWells(plate));

//Select the indexes of all plates that have a hfCas12Max nuclease selected
export const selectHfCas12MaxPlates = project =>
  project.plates.reduce((result, plate, idx) => {
    if (plate.nuclease === 'hfCas12Max') {
      result.push({
        plateIdx: idx
      });
    }
    return result;
  }, []);

//Select the indexes of all plates that don't have the minimum amount of wells filled
export const selectInvalidWellCountPlates = project =>
  _.reduce(
    project.plates,
    (result, plate, idx) => {
      if (
        selectFilledPlateWells(plate).length < orderRequirements.minPlateWells
      ) {
        result.push({
          plateIdx: idx
        });
      }
      return result;
    },
    []
  );

//Select the indexes of all plates that have an invalid plate name
export const selectInvalidNamePlates = project =>
  _.reduce(
    project.plates,
    (result, plate, idx) => {
      if (getPlateNameErrors(plate.name, project.plates)?.length > 0) {
        result.push({
          plateIdx: idx
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all invalid sequence wells on the project
export const selectInvalidSequenceWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectInvalidSequencePlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all invalid name wells on the project
export const selectNonUniqueNameWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectNonUniqueNamePlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select the indexes and well objects of all invalid name wells on the project
export const selectInvalidNameWellPlates = project =>
  _.reduce(
    _.cloneDeep(project.plates),
    (result, plate, idx) => {
      //Store the index of the invalid well so we can show it's location to the user
      plate.wells = plate.wells.map((w, idx) => ({ ...w, index: idx }));
      const invalidWells = selectInvalidNamePlateWells(plate);
      if (invalidWells.length) {
        result.push({
          plateIdx: idx,
          wells: invalidWells
        });
      }
      return result;
    },
    []
  );

//Select tubes that are filled
export const selectFilledTubes = project =>
  _.filter(
    project.tubes,
    tube => !_.isEmpty(tube?.name) || !_.isEmpty(tube?.sequence)
  );

//Select hfCas12Max tubes
export const selectHfCas12MaxTubes = project =>
  project.tubes
    .filter(tube => tube?.nuclease === 'hfCas12Max')
    .map(tube => ({ ...tube, tubeIdx: project.tubes.indexOf(tube) }));

//Select all tubes that have a SEQUENCE_INVALID error
export const selectInvalidSequenceTubes = project =>
  _.filter(project.tubes, tube =>
    tube.errors?.includes(ERRORS.SEQUENCE_INVALID)
  ).map(tube => ({ ...tube, tubeIdx: project.tubes.indexOf(tube) }));

//Select all tubes that have a NAME_INVALID error
export const selectInvalidNameTubes = project =>
  _.filter(project.tubes, tube =>
    tube.errors?.includes(ERRORS.NAME_INVALID)
  ).map(tube => ({ ...tube, tubeIdx: project.tubes.indexOf(tube) }));

//Select all tubes that have a NAME_NON_UNIQUE error
export const selectNonUniqueNameTubes = project =>
  _.filter(project.tubes, tube =>
    tube.errors?.includes(ERRORS.NAME_NON_UNIQUE)
  ).map(tube => ({ ...tube, tubeIdx: project.tubes.indexOf(tube) }));

//Swap the position of two items in an array (used for drag and drop)
export const arraySwap = (array, from, to) => {
  let newArray = array.slice();
  var temp = newArray[from];
  newArray[from] = newArray[to];
  newArray[to] = temp;

  return newArray;
};

//Given a plate array, rearrange it from a vertical to horizontal orientation and vice-versa
export const arrayFlipDirection = (array, plateType) => {
  const { wellRows, wellColumns } = plateType;
  let newArray = _.range(0, wellRows * wellColumns).fill('');
  for (let i = 0; i < array.length; i++) {
    newArray[
      (i - wellColumns * Math.floor(i / wellColumns)) * wellRows +
        Math.floor(i / wellColumns)
    ] = array[i];
  }

  return newArray;
};

//Given an array index, calulate it's well position based on the number of rows on the plate
export const indexToWellLocation = (index, rows) => {
  const row = String.fromCharCode(
    65 + (index - rows * Math.floor(index / rows))
  );
  const column = Math.floor(index / rows) + 1;
  return `${row}${column}`;
};

//Given a well position, calulate it's index in an array of wells
export const wellLocationtoIndex = (location, rows) => {
  const row = location.charCodeAt(0) - 65;
  const column = location.slice(1) - 1;
  return row + column * rows;
};

//Format a sequences symbol's capitalization based on genome
export const formatSequencesymbol = (symbol, genome) => {
  //If human uppercase all
  if (genome === 'human') {
    return symbol.toUpperCase();
    //If mouse capitalize only if symbol doesn't start with number or is 'a' (a valid mouse sequences symbol)
  } else if (
    genome === 'mouse' &&
    !isFinite(symbol.charAt(0)) &&
    symbol !== 'a'
  ) {
    return _.capitalize(symbol);
  } else {
    return symbol;
  }
};

//Convert an array of sequences to an array of well objects, validating the sequences
export const sequencesArrayToWells = (sequences, project) => {
  const { plateType } = project;
  let wellsArray = [];

  for (let i = 0; i < plateType.wellCount; i++) {
    let errors = [];

    //Validate name
    if (getNameErrors(sequences[i]?.name)?.length) {
      errors.push(ERRORS.NAME_INVALID);
    }
    if (getNameUniqueErrors(sequences[i]?.name, sequences)?.length) {
      errors.push(ERRORS.NAME_NON_UNIQUE);
    }
    //Validate sequence
    if (getSequenceErrors(sequences[i]?.sequence)?.length) {
      errors.push(ERRORS.SEQUENCE_INVALID);
    }
    wellsArray[i] = {
      id: `well-${shortid.generate()}`,
      name: sequences[i]?.name,
      sequence: sequences[i]?.sequence,
      errors: errors
    };
  }
  return wellsArray;
};

//Convert an array of sequences to an array of tube objects, validating the sequences
export const sequencesArrayToTubes = (project, sequences) => {
  let tubesArray = [];

  for (let i = 0; i < sequences.length; i++) {
    let errors = [];

    //Validate name
    if (getNameErrors(sequences[i]?.name)?.length) {
      errors.push(ERRORS.NAME_INVALID);
    }
    if (getNameUniqueErrors(sequences[i]?.name, sequences)?.length) {
      errors.push(ERRORS.NAME_NON_UNIQUE);
    }
    //Validate sequence
    if (getSequenceErrors(sequences[i]?.sequence)?.length) {
      errors.push(ERRORS.SEQUENCE_INVALID);
    }
    tubesArray[i] = {
      id: `tube-${shortid.generate()}`,
      name: sequences[i]?.name,
      sequence: sequences[i]?.sequence,
      errors: errors,
      modifications: ['NO', 'FALSE', 'N', 'NO_MODS'].includes(
        (sequences[i]?.modified || sequences[i]?.modifications)?.toUpperCase()
      )
        ? false
        : true,
      nuclease:
        sequences[i]?.nuclease || defaultNucleaseOptions[project.productSlug],
      yield:
        sequences[i]?.yield || defaultYieldOptions[project.productSlug].tubes
    };
  }
  return tubesArray;
};

//Given csv text, determine which template type is being used
export const getTemplateType = sequencesText => {
  let rows = sequencesText.split('\n');
  if (rows.length) {
    rows = rows.map(cell => cell.replace(/['"\r]+/g, '').split(','));
    //Template has headers for grid layout
    if (
      rows[0][0] === 'Well Position' &&
      rows[0][1] === 'Name' &&
      rows[0][2] === 'Sequence'
    ) {
      //Template has well positions in correct locations
      if (rows[9][0] === 'A2') {
        //A1, B1, C1 etc.
        return 'plates';
      } else if (rows[9][0] === 'A9') {
        //A1, A2, A3 etc.
        return 'plates-legacy';
      } else {
        return false;
      }
      //Template has headers for tube layout
    } else if (
      rows[0][0] === 'Name' &&
      rows[0][1] === 'Sequence' &&
      (rows[0][2] === 'Modifications' || rows[0][2] === 'Modified')
    ) {
      return 'tubes';
      //Otherwise, not a valid template
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isValidTemplate = (sequencesText, format) => {
  try {
    const templateType = getTemplateType(sequencesText);
    if (templateType && templateType.startsWith(format.slug)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const cleanName = name => _.trim(name); // _.trim() returns "" for null values

export const cleanSequence = sequence =>
  sequence?.replace(/\s+/g, '')?.toUpperCase() || '';

//Pick the proper conversion and return it based on the csv template provided
export const sequenceCsvToArray = (sequencesText, project) => {
  const templateType = getTemplateType(sequencesText);
  let sequencesArray;
  let rows = sequencesText.split('\n');
  rows.shift(); //Remove header
  sequencesArray = rows.map(row => {
    let rowArray = row.split(',');
    if (project.format?.slug === 'plates') {
      return {
        name: cleanName(rowArray[1]),
        sequence: cleanSequence(rowArray[2])
      };
    } else if (project.format?.slug === 'tubes') {
      if (!(_.isEmpty(rowArray[0]) && _.isEmpty(rowArray[1]))) {
        return {
          name: cleanName(rowArray[0]),
          sequence: cleanSequence(rowArray[1]),
          modifications: rowArray[2],
          nuclease: rowArray[3]
        };
      } else {
        return null;
      }
    } else {
      return { name: '', sequence: '' };
    }
  });
  if (project.format?.slug === 'plates') {
    //Trim to either the length of the array or the plate size, whichever is smaller
    sequencesArray.length = Math.min(
      sequencesArray.length,
      project.plateType.wellCount
    );
  }
  if (templateType === 'plates-legacy') {
    sequencesArray = arrayFlipDirection(sequencesArray, project.plateType);
  }
  return sequencesArray.filter(Boolean);
};

//Convert an entire project into an .xlsx workbook in a vertical list layout
export const downloadProject = async project => {
  try {
    const XLSX = await import('xlsx');
    const { plateType, plates, tubes, format } = project;
    let workbook = XLSX.utils.book_new();
    if (format.slug === 'plates') {
      plates.forEach((plate, plateIdx) => {
        const plateArray = [['Well Position', 'Name', 'Sequence']];
        plate.wells.forEach((well, wellIdx) => {
          plateArray.push([
            indexToWellLocation(wellIdx, plateType.wellRows),
            well.name,
            well.sequence
          ]);
        });
        let sheet = XLSX.utils.aoa_to_sheet(plateArray);
        XLSX.utils.book_append_sheet(
          workbook,
          sheet,
          plate.name ? plate.name.substring(0, 30) : `Plate ${plateIdx + 1}`
        );
      });
    } else if (format.slug === 'tubes') {
      const tubeArray = [['Name', 'Sequence', 'Modifications', 'Nuclease']];
      tubes.forEach((tube, tubeIdx) => {
        tubeArray.push([
          tube.name,
          tube.sequence,
          tube.modifications ? 'Yes' : 'No',
          tube.nuclease
        ]);
      });
      let sheet = XLSX.utils.aoa_to_sheet(tubeArray);
      XLSX.utils.book_append_sheet(workbook, sheet, 'Tubes');
    }
    XLSX.writeFile(
      workbook,
      `synthego-sgrna-${format.slug}-list-${dayjs().format(
        `YYYY-MM-DDTHHmmss`
      )}.xlsx`
    );
  } catch (error) {
    alert(error);
  }
};

export const getPlateNameErrors = (name, plates) => {
  const errors = [];
  const matchingNames = _.filter(plates, plate => plate.name === name);

  if (!name || name.length === 0) errors.push('Plate name is required.');
  if (name && name.length > 20)
    errors.push('Plate name must be 15 or fewer characters.');
  if (!name?.match(/^[A-Za-z0-9_ -]*$/))
    errors.push(
      'Plate name must only contain alphanumerics, spaces, underscores, and hyphens.'
    );
  if (name?.length && matchingNames.length > 1)
    errors.push('Plate name must be unique.');
  return errors;
};

export const getNameErrors = name => {
  const errors = [];
  if (!name || name.length === 0) errors.push('Name is required.');
  if (name && name.length > 20)
    errors.push('Name must be 20 or fewer characters.');
  if (!name?.match(/^[A-Za-z0-9_ -]*$/))
    errors.push(
      'Name must only contain alphanumerics, spaces, underscores, and hyphens.'
    );
  return errors;
};

export const getNameUniqueErrors = (name, items) => {
  const errors = [];
  const matchingNames = _.filter(items, item => item.name === name);
  if (name?.length && matchingNames.length > 1)
    errors.push('Name must be unique.');
  return errors;
};

export const getNameAlreadyExistsInCartErrors = (name, usedTubeNames) => {
  const errors = [];
  if (_.includes(usedTubeNames, name))
    errors.push('A tube with this name already exists in your cart.');
  return errors;
};

export const getSequenceErrors = (sequence, productSlug, nuclease) => {
  const errors = [];
  const seq = sequence?.toUpperCase() || '';
  if (seq.length === 0) errors.push('Sequence is required.');

  if (productSlug === 'sgrna_kit') {
    if (nuclease === 'SpCas9') {
      if (seq?.length && seq.length < 17)
        errors.push('Sequence must be at least 17 characters.');
      if (seq && seq.length > 35)
        errors.push('Sequence must be 35 or fewer characters.');
    }
    if (nuclease === 'hfCas12Max') {
      if (seq?.length && seq.length < 17)
        errors.push('Sequence must be at least 17 characters.');
      if (seq && seq.length > 23)
        errors.push('Sequence must be 23 or fewer characters.');
    }
  }
  if (productSlug === 'custom_rna') {
    if (seq?.length && seq.length < 20)
      errors.push('Sequence must be at least 20 characters.');
    if (seq && seq.length > 115)
      errors.push('Sequence must be 115 or fewer characters.');
  }
  if (!seq.match(/^[ACGU]*$/))
    errors.push(
      'A non-RNA character has been entered. Please only use A, C, G, and U.'
    );
  return errors;
};

export const getItemName = (format, item, itemIdx) => {
  if (format?.slug === 'plates') {
    return `Plate ${itemIdx + 1} ${
      item.name !== `Plate ${itemIdx + 1}` ? ` (${item.name})` : ''
    }`;
  }
  if (format?.slug === 'tubes') {
    return `Kit #${itemIdx + 1} ${item.name ? ` (${item.name})` : ''}`;
  }
};

export const numericYield = function(yieldObject) {
  return parseFloat(yieldObject.display.split(' ')[0]);
};

export const yieldUnit = function(yieldObject) {
  return yieldObject.display.replace(/[0-9.\s]/g, '');
};

//Given product options, return the yield options for that project and user
export const getProjectYieldOptions = (productPricing, project) => {
  const { productSlug, format } = project;
  const defaultYieldOptions =
    _.find(productPricing?.default_options, {
      product_slug: productSlug
    })?.yield_options || [];
  const userYieldOptions =
    _.find(productPricing?.user_options, {
      product_slug: productSlug
    })?.yield_options || [];

  const yieldOptions = _.unionBy(userYieldOptions, defaultYieldOptions, 'id');

  if (format?.slug === 'plates') {
    return _.filter(yieldOptions, option => numericYield(option) <= 10);
  }

  return yieldOptions;
};

export const getProjectNucleaseOptions = () => {
  const nucleaseOptions = hfCas12MaxBlockedCountries.includes(
    Cookies.get('ip_country')
  )
    ? ['SpCas9', 'Other']
    : ['SpCas9', 'hfCas12Max'];

  return nucleaseOptions;
};

export const getMostPrevalentNuclease = project => {
  let projectArray = null;
  if (project.tubes.length > 0) {
    projectArray = project.tubes;
  } else if (project.plates.length > 0) {
    projectArray = project.plates;
  } else {
    return projectArray;
  }
  return _.last(projectArray).nuclease;
};

//For Advanced RNA plates, group plate wells by length for length-based pricing display
export const groupPriceItemsByLength = (
  productPricing,
  project,
  priceItems
) => {
  const { productSlug } = project;

  const availablePrices = filterPrices(productPricing, {
    product_slug: productSlug,
    price_type: 'price'
  });

  const availableLengths = _.uniqWith(
    availablePrices.map(item => ({
      min: item.min_length,
      max: item.max_length
    })),
    _.isEqual
  );

  let groupedItems = [];
  priceItems.forEach((item, itemIdx) => {
    const groupedWells = _.groupBy(
      item.wells,
      well =>
        _.find(
          availableLengths,
          l => well.sequence.length >= l.min && well.sequence.length <= l.max
        )?.max
    );
    delete groupedWells[undefined];

    _.forOwn(groupedWells, (value, key) => {
      groupedItems.push({
        ...item,
        wells: value,
        originalIndex: itemIdx,
        groupedLength: parseInt(key)
      });
    });
  });
  return groupedItems;
};

export const formatProjectForCart = sgrnaProject => {
  const {
    format,
    genome,
    cellLine,
    productSlug,
    tubes,
    plates,
    addOns
  } = sgrnaProject;

  const sgrnaProjectForCart = [];

  if (format?.slug === 'plates') {
    plates.forEach(plate => {
      const sequencesForCart = [];
      plate.wells.forEach((well, idx) => {
        if (well.sequence) {
          sequencesForCart.push({
            name: well.name,
            raw_sequence: well.sequence,
            well: indexToWellLocation(idx, sgrnaProject.plateType.wellRows)
          });
        }
      });
      sgrnaProjectForCart.push({
        product_slug: productSlug,
        cell_line: cellLine?.name || cellLine,
        species: genome?.commonName,
        guaranteed_yield: plate.yield.id,
        mod: plate.modifications ? 'stb_methyl_pp_thioate' : 'no_mods',
        nuclease: plate.nuclease,
        plate_name: plate.name,
        product_format: 'plate',
        sequences: sequencesForCart.filter(Boolean)
      });
    });
  }

  if (format?.slug === 'tubes') {
    tubes.forEach(tube => {
      sgrnaProjectForCart.push({
        product_slug: productSlug,
        cell_line: cellLine?.name || cellLine,
        species: genome?.commonName,
        guaranteed_yield: tube.yield.id,
        mod: tube.modifications ? 'stb_methyl_pp_thioate' : 'no_mods',
        nuclease: tube.nuclease,
        product_format: 'tube',
        sequences: [
          {
            name: tube.name,
            raw_sequence: tube.sequence
          }
        ]
      });
    });
  }

  addOns.forEach(addOn => {
    sgrnaProjectForCart.push(addOn);
  });
  return sgrnaProjectForCart;
};

export const formatOrderItemForProject = (orderItem, project) => {
  const { product_slug, data } = orderItem;
  const formattedYield = _.find(defaultYieldOptions[product_slug], [
    'id',
    data?.guaranteed_yield
  ]) || {
    id: data?.guaranteed_yield,
    display: data?.guaranteed_yield
  };

  let formattedSequences;
  if (data?.product_format === 'tube') {
    formattedSequences = {
      tubes: sequencesArrayToTubes(
        project,
        data.sequences.map(sequence => {
          return {
            name: sequence.name,
            sequence: sequence.raw_sequence,
            modifications: data?.mod,
            nuclease: data?.nuclease,
            yield: formattedYield
          };
        })
      )
    };
  }

  if (data?.product_format === 'plate') {
    const sequencesArray = _.range(0, project?.plateType.wellCount).map(() => ({
      name: '',
      sequence: ''
    }));

    for (let i = 0; i < data.sequences?.length; i++) {
      const wellIndex = wellLocationtoIndex(
        data.sequences[i]?.well,
        project?.plateType.wellRows
      );
      sequencesArray[wellIndex] = {
        name: data.sequences[i].name,
        sequence: data.sequences[i].raw_sequence
      };
    }

    formattedSequences = {
      plates: [
        {
          loading: false,
          id: `plate-${shortid.generate()}`,
          new: false,
          name: data?.plate_name,
          modifications: data?.mod === 'stb_methyl_pp_thioate' ? true : false,
          nuclease: data?.nuclease,
          yield: formattedYield,
          wells: sequencesArrayToWells(sequencesArray, project)
        }
      ]
    };
  }

  return {
    productSlug: product_slug,
    genome: _.find(genomeOptions, ['commonName', data?.species]),
    cellLine: data?.cell_line,
    format: _.find(formatOptions[project?.productSlug], [
      'slug',
      `${data?.product_format}s`
    ]),
    ...formattedSequences
  };
};
